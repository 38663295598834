import React from 'react';
import { useSelector } from 'react-redux';
import './loading.css';
import classNames from 'classnames';

// 水平垂直置中
const styles = {
    //container: {
    //    display: 'flex',
    //    justifyContent: 'center',
    //    alignItems: 'center',
    //}

}

export const Loading = (props) => {

    return (
        <div
            //背景模糊 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                backdropFilter: 'blur(5px)',
                WebkitBackdropFilter: 'blur(5px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: '1000'
            }}>
            <div className="loader-container" style={styles.container}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ margin: "auto", background: "none", display: "block" }}
                    width="100px"
                    height="100px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                >
                    <circle
                        cx="50" //圓心的位置
                        cy="50" //圓心的位置
                        r="20" //圓半徑
                        strokeWidth="6" //圓邊框像素
                        stroke="#25b394" //圓邊框的顏色
                        strokeDasharray="50.26548245743669 50.26548245743669" //定義線條的虛線樣式
                        fill="none" //圓的填充顏色為「無」，因此只有邊框（stroke）是可見的
                        strokeLinecap="round" //指定線條端點的樣式
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate" //指定動畫的類型，這裡是旋轉（rotate）
                            repeatCount="indefinite" //設定動畫將無限重複
                            dur="1s" //動畫的持續時間為 1 秒，每次旋轉一圈所需的時間
                            keyTimes="0;1" //設置動畫進程的關鍵時間點。這裡的 0;1 表示動畫從 0%（開始）到 100%（結束）之間的時間點
                            values="0 50 50;360 50 50" //定義旋轉的起始和結束角度。這裡的值表示旋轉中心是 (50, 50)，從 0 度旋轉到 360 度，即繞著圓心旋轉一整圈
                        />
                    </circle>
                </svg>
                <div style={{fontSize: '16px', color: '#25b394' }}>
                    <b>資料載入中...</b>
                </div>
            </div>

        </div>
    );

}

export const MaskLoad = React.memo((props) => {
    const { MaskLoading } = useSelector((x) => x.ui);

    const isShow = (state) => {
        return classNames({
            'show': state === 'block',
            'hide': state === 'none',
        });
    };

    return (
        <div className={isShow(MaskLoading.display)}>            
            <Loading />
        </div>
    );
});



