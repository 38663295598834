import React from 'react';
import { Card, Row, Button, Container, InputGroup,  Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../Navbar/NavBar.jsx';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialLogQuery } from '../../action/material/meterialLogAction.js';

import { store } from 'react-notifications-component';


const completeMessage = {
    title: "更新",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
export const MaterialLog = (props) => {


    const dispatch = useDispatch();
   
    const { log } = useSelector(x => x);
    let filteredData = log.materialLog;

    const handleMsgContext = (data) => {

        let snArray = [];
        snArray = data.split('\r\n');

        let br = <br></br>;
        let result = null;

        if (snArray.length < 2) {
            return data;
        }

        for (let i = 0; i < snArray.length; i++) {
            if (i === 0) {
                result = snArray[i];
            } else {
                result = <span>{result}{br}{snArray[i]}</span>;
            }
        }
        return <div>{result}</div>;
    }
    

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Card.Header>產品設定</Card.Header>
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Row className="mx-auto">
                                <InputGroup className="mb-3">

                                    <Button variant="primary" data-toggle="tooltip" data-placement="bottom" title="更新Log，按下無更新代表無錯誤訊息" onClick={() => {
                                        dispatch(MaterialLogQuery());
                                        store.addNotification(completeMessage);
                                    }}>
                                        <FontAwesomeIcon className='rounded' icon={faSearch} />
                                        更新
                                    </Button>
                                </InputGroup>
                                
                            </Row>
                            <Row className="mx-auto">


                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr> 
                                            <th>時間</th>
                                            <th>狀態</th>
                                            <th>項目</th>
                                            <th>類別</th>
                                            <th>訊息(重複標題: 銷貨單、銷貨明細、品名、UDI、防偽條碼)</th>
                                        </tr>
                                    </thead>
                                    <tbody  >
                                        {
                                            filteredData.map((item, index) => {
                                                return (

                                                    <tr key={index}>
                                                        <td>{item.updatedDate ? item.updatedDate.replace('T', ' ').substr(0, 19) : ''}</td>
                                                        <td>{item.state}</td>
                                                        <td>{item.item}</td>
                                                        <td>{item.type}</td>
                                                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-word' }} >{handleMsgContext(item.message)}</td>
                                                    </tr>
                                                )
                                            })

                                        }
                                    </tbody>
                                </Table>
                            </Row>

                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );

}



